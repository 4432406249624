.anci-check{
    width: 13px;
    height: 13px;
    border-radius: 2px;
    border: 2px solid #ff7139;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.anci-check i{
    /* display: none; */
    color: #ff7139
}
.anci-clicked{
    background-color:#fff;
    border: 2px solid #ff7139;
}