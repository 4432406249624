.radio{
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.radio-clicked{
    padding: 3px;
    border: 4px solid #ff7139;
    background-color: #fff;
}