
.ancillary-head{
    margin: 5rem 3rem;
}
/* .data-head::before{
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color:rgb(136, 135, 135) ;
    margin: -3rem 0;
    opacity: 0.2;
} */
.ancillary-head h3{
    font-size: 1.6rem;
    color:#1ee9d1;
    font-weight: 600;
}
.ancillary-checkbox{
    display: flex;
}
.ancillary-head .ancillary-checkbox .checkbox-items{
    display: flex;
    align-items: center;
    margin-right: 4rem;
    font-size: 1.4rem;
    font-weight: 500;
    position: relative;
}
.checkbox-items p{
    margin: 0 1rem;
    font-family: "Helvetica Neue",Roboto,Arial,"Droid Sans",sans-serif;
    align-self: center;
}
.checkbox-items input[type=checkbox],input[type=radio]{
    height: 15px;
    width: 15px;
    padding: 2px;
    border: 1px solid #ff7139;
    border-radius: 6px;
    transition-duration: 0.3s;
    background-color: #ff7139;
    cursor: pointer;
    margin-top:0 ;
}
input[type=checkbox]:checked{
    background-color: white;
    color: #ff7139;
}
.checkbox-items input[type=text]{
    height: 25px;
    width: 110px; 
    background-color: transparent;  
    border: 2px solid rgb(92, 89, 89);
    outline: none;
    padding: 13px;
    border-radius: 3px;
}
.ancillary-radio{
    display: flex;
}
.checkbox-items select > option{
    background-color: rgb(92, 89, 89);  
    border: 2px solid rgb(92, 89, 89);
    outline: none;
    padding: 13px;
    border-radius: 3px;
    color: white;
}
.select-container{
   display: flex;
   flex-direction: column;
   margin-top: 4rem;
}
.select-box{
    margin-top: 2rem;
}
.empty{
    display: flex;
}
.empty-container{
    width: 50%;
}
.data-content{
    display: flex;
    align-items: center;
}
.data-para{
    width: 20%;
}
.data-radio{
    display: flex;
    align-items: center;
    margin-top: 1rem;
}
.data-radio .checkbox-items{
    display: flex;
    margin-right: 4rem;
} 
.data-radio .checkbox-items input{
    margin: 0 1rem;
}

.page-size{
    padding: 20px;
    background-color: #2e303f;
    margin: 10px;
    height: calc(100vh - 72px);
    overflow: auto;
}
.checkbox-items input[type=date]{
    height: 25px;
    width: 130px; 
    background-color: transparent;  
    border: 2px solid rgb(92, 89, 89);
    outline: none;
    padding: 13px;
    border-radius: 3px;
}
.add-update{
    display: flex;
}
.add-btn{
    width: 180px;
    margin-right: 3rem;
    margin-top: 3rem;
}
.add-btn button{
    background-color: #ff7139;
    color: #fff;
    padding: 0.8rem;
    border: none;
    border-radius: 2px;
    width: 100%;
}
.edit-btn{
    color:#ff7139 ;
    background-color: transparent;
    border: none;
    text-decoration: underline;
}
table{
    margin-top: 3rem;
}
table,th,.border{
    border: 1px solid rgb(136, 135, 135);
}
.border-btn{
    border: none;
    padding: 5px 15px;
}
th{
    padding: 15px;
}
.sub-head{
    border: none;
    border-right: 1px solid rgb(136, 135, 135);
}
.slider{
    position: relative;
    margin-top: 2rem;
    width: 200px;
    height: 1px;
    border-radius: 2px;
    background-color: #f8714f93;
}
.slider::before{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    content: '';
    width: 10px;
    height: 10px;
    background-color: #f85948;
    border-radius: 50%;
}
.range-num{
    width: 200px;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}
#range{
    color:#ff7139;
    font-weight: 600;
    font-size: 1.2rem;
}
.date-content{
    display: flex;
    align-items: center;
    margin-top: 2rem;
}
.date-items{
    height: 25px;
    width: 130px; 
    background-color: transparent;  
    border: 2px solid rgb(92, 89, 89);
    padding: 15px 8px;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items:center ;
    margin-left: 2rem;
    cursor: pointer;
}
.date-items p,i{
    margin: 0;
    font-size: 1.2rem;
    color: rgb(184, 179, 179);
}
.dropdown{
    display: flex;
}
.dropdown-container{
    width: 130px;
    background-color: #2e303f;  
    border: 2px solid rgb(92, 89, 89);
    padding: 15px 8px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center ;
    color: #fff;
    margin-top: 0.3rem;
    margin-left: 8.2rem;
    cursor: pointer;
    display: none;
    position: absolute;
    z-index: 10;
}
.drop-show{
    display: block;
}
.dropdown-2{
    margin-left: 33.75rem;
}
.downItems{
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0.5rem;
}
.checks{
    margin-left: 1rem;
    margin-right: 0;
}
.drop-span{
    margin-left:  1rem;
    margin-right: 2rem;
    text-align: left;
}
.cal{
    color: rgb(184, 179, 179);
    cursor: pointer;
}

.dow-container{
    width: 130px;
    background-color: #2e303f;  
    border: 2px solid rgb(92, 89, 89);
    padding: 15px 8px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center ;
    color: #fff;
    margin-top: 11rem;
    margin-left:2rem;
    cursor: pointer;
    display: none;
    position: absolute !important;
    z-index: 10;
}
.dow-show{
    display: block;
}
.sector-container{
    width: 130px;
    height: 200px;
    background-color: #2e303f;  
    border: 2px solid rgb(92, 89, 89);
    padding: 10px 4px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center ;
    color: #fff;
    margin-top: 5.5rem;
    margin-left:2rem;
    cursor: pointer;
    display: none;
    position: absolute;
    z-index: 10;
    overflow: scroll;
    font-size: 1.2rem;
}
.sector-show{
    display: block;
}
.flight-container{
    width: 130px;
    height: 200px;
    background-color: #2e303f;  
    border: 2px solid rgb(92, 89, 89);
    padding: 10px 4px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center ;
    color: #fff;
    margin-top: 5.5rem;
    margin-left:2rem;
    cursor: pointer;
    display: none;
    position: absolute;
    z-index: 10;
    overflow: scroll;
    font-size: 1.2rem;
}
.flight-show{
    display: block;
}
.od-container{
    width: 130px;
    height: 200px;
    background-color: #2e303f;  
    border: 2px solid rgb(92, 89, 89);
    padding: 10px 4px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center ;
    color: #fff;
    margin-top: 5.5rem;
    margin-left:2rem;
    cursor: pointer;
    display: none;
    position: absolute;
    z-index: 10;
    overflow: scroll;
    font-size: 1.2rem;
}
.od-show{
    display: block;
}
.bi-calendar3{
    position: relative;
    top: -2.7rem;
    left: 12rem;
    font-size: 1.4rem;
    cursor: pointer;
}