.check{
    width: 13px;
    height: 13px;
    border-radius: 2px;
    border: 2px solid #fff;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.check i{
    /* display: none; */
    color: #fff;
}
.clicked{
    background-color: #ff7139;
    color: #fff;
    border: 2px solid #ff7139;
}